import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-49cc5f22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "relative flex items-center justify-between top-0" }
const _hoisted_3 = { class: "w-60 max-w-full px-4" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex w-full items-center justify-between px-4" }
const _hoisted_8 = {
  ref: "nav",
  class: "navbar hidden lg:block"
}
const _hoisted_9 = { class: "flex items-end -mt-2 mr-2 justify-end lg:hidden" }
const _hoisted_10 = { class: "lg:flex" }
const _hoisted_11 = ["href", "onClick", "target"]
const _hoisted_12 = {
  key: 2,
  class: "submenu lg:hidden block mb-6"
}
const _hoisted_13 = { class: "submenu-content" }
const _hoisted_14 = ["href", "onClick"]
const _hoisted_15 = { class: "icon text-primary-300 group-hover/navitem:text-primary mx-4 flex flex-wrap justify-center align-center content-center" }
const _hoisted_16 = { class: "link w-full" }
const _hoisted_17 = { class: "text-primary" }
const _hoisted_18 = { class: "text-xs text-slate-600" }
const _hoisted_19 = { class: "flex flex-wrap justify-center align-center content-center" }
const _hoisted_20 = { class: "flex items-center justify-center lg:hidden p-4" }
const _hoisted_21 = {
  href: "https://building.larva.io",
  target: "_blank",
  rel: "noopener",
  class: "btn-login w-full"
}
const _hoisted_22 = { class: "hidden lg:block" }
const _hoisted_23 = { class: "hidden lg:block" }
const _hoisted_24 = {
  href: "https://building.larva.io",
  target: "_blank",
  rel: "noopener",
  class: "btn-login"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Locale = _resolveComponent("Locale")!
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (!_ctx.loading && _ctx.currentLocale && _ctx.global && _ctx.nav)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["menu flex left-0 top-0 z-40 flex w-full justify-center items-center transition-all", {
      sticky: _ctx.scrolledDown, absolute: !_ctx.scrolledDown, 'bg-transparent': !_ctx.scrolledDown, 'bg-white': _ctx.scrolledDown, 'shadow-lg': _ctx.scrolledDown, 'border-bottom': _ctx.scrolledDown,
    }])
      }, [
        _createElementVNode("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("a", {
                href: `/${_ctx.currentLocale.code}/home`,
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.route(`/${_ctx.currentLocale.code}/home`)), ["prevent"])),
                class: "block w-full py-5"
              }, [
                _createElementVNode("img", {
                  src: require('@/assets/fentrica-white.svg'),
                  alt: "logo",
                  class: _normalizeClass({ hidden: _ctx.scrolledDown })
                }, null, 10, _hoisted_5),
                _createElementVNode("img", {
                  src: require('@/assets/fentrica.svg'),
                  class: _normalizeClass({ hidden: !_ctx.scrolledDown }),
                  alt: "logo"
                }, null, 10, _hoisted_6)
              ], 8, _hoisted_4)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  class: "navtoggle-btn",
                  "aria-label": "Navigation",
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggleMenu()), ["prevent"]))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["navtoggle-stripe", { 'navtoggle-stripe-scrolled': _ctx.scrolledDown }])
                  }, null, 2),
                  _createElementVNode("span", {
                    class: _normalizeClass(["navtoggle-stripe", { 'navtoggle-stripe-scrolled': _ctx.scrolledDown }])
                  }, null, 2),
                  _createElementVNode("span", {
                    class: _normalizeClass(["navtoggle-stripe", { 'navtoggle-stripe-scrolled': _ctx.scrolledDown }])
                  }, null, 2)
                ]),
                _createElementVNode("nav", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_Locale)
                  ]),
                  _createElementVNode("ul", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "group relative",
                        key: item.id
                      }, [
                        (!item.items?.length)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: _normalizeClass([{ 'nav-item-scrolled': _ctx.scrolledDown, 'nav-item-start': !_ctx.scrolledDown }, "nav-item"]),
                              href: item.type === 'EXTERNAL' ? item.path : `/${_ctx.currentLocale?.code}${item.path}`,
                              onClick: _withModifiers(($event: any) => (_ctx.route(item.type === 'EXTERNAL' ? item.path : `/${_ctx.currentLocale?.code}${item.path}`)), ["prevent"]),
                              target: item.type === 'EXTERNAL' ? '_blank' : '_self'
                            }, _toDisplayString(item.title), 11, _hoisted_11))
                          : _createCommentVNode("", true),
                        (item.items?.length)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              class: _normalizeClass([{ 'nav-item-scrolled': _ctx.scrolledDown, 'nav-item-start': !_ctx.scrolledDown }, "nav-item"]),
                              href: "#",
                              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                            }, [
                              _createTextVNode(_toDisplayString(item.title) + " ", 1),
                              _createVNode(_component_fa_icon, {
                                class: "ml-2 hidden lg:inline-block",
                                icon: "chevron-down"
                              })
                            ], 2))
                          : _createCommentVNode("", true),
                        (item.items?.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subitem) => {
                                  return (_openBlock(), _createElementBlock("a", {
                                    class: "flex p-2 lg:p-4 group/navitem lg:rounded-lg cursor-pointer hover:bg-surface-50 hover:text-primary",
                                    href: subitem.type === 'EXTERNAL' ? subitem.path : `/${_ctx.currentLocale?.code}${subitem.path}`,
                                    onClick: _withModifiers(($event: any) => (_ctx.route(subitem.type === 'EXTERNAL' ? subitem.path : `/${_ctx.currentLocale?.code}${subitem.path}`)), ["prevent"]),
                                    key: subitem.id
                                  }, [
                                    _createElementVNode("div", _hoisted_15, [
                                      _createVNode(_component_fa_icon, {
                                        icon: subitem.faIcon,
                                        size: "xl"
                                      }, null, 8, ["icon"])
                                    ]),
                                    _createElementVNode("div", _hoisted_16, [
                                      _createElementVNode("div", _hoisted_17, [
                                        _createElementVNode("span", null, _toDisplayString(subitem.title), 1)
                                      ]),
                                      _createElementVNode("p", _hoisted_18, _toDisplayString(subitem.description), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_19, [
                                      _createVNode(_component_fa_icon, {
                                        class: "ml-2 invisible group-hover/navitem:visible inline-block",
                                        icon: "chevron-right"
                                      })
                                    ])
                                  ], 8, _hoisted_14))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("a", _hoisted_21, _toDisplayString(_ctx.global.loginButton), 1)
                  ])
                ], 512)
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_Locale, {
                class: _normalizeClass(["pr-2", { 'text-white': !_ctx.scrolledDown }])
              }, null, 8, ["class"])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("a", _hoisted_24, _toDisplayString(_ctx.global.loginButton), 1)
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}